import React from "react";
import { SectionHeader } from "../../common/SectionHeader";
import { SectionContent } from "../../common/SectionContent";
import { ImageContainer } from "../../common/ImageContainer";
import { SubSectionHeader } from "../../common/SubSectionHeader";

export const ManageForecastScenarios = () => {
  return (
    <React.Fragment>
      <React.Fragment>
        <SectionHeader title='Managing Forecast Scenarios'>
          <SectionContent>
            <ImageContainer
              fileName='managing_forecast_scenarios.png'
              alt='List of scenarios'
            />
            <ol>
              <li>
                Accessing the 'Manage Scenarios' Tab:
                <ul>
                  <li>
                    Navigate to the left navigation menu and find the 'Manage'
                    link. Alternatively, you can go to 'Settings' and select
                    'Manage Forecasts and Scenarios.'
                  </li>
                  <li>
                    Click on 'Manage Scenarios' tab to access the list of
                    in-progress forecasts and their respective scenarios.
                  </li>
                </ul>
              </li>
              <li>
                Viewing Scenarios for a Forecast:
                <ul>
                  <li>
                    On the 'Manage Scenarios' tab, you will see a list of all
                    in-progress forecasts along with their associated scenarios.
                  </li>
                  <li>
                    Select a specific forecast to view all the scenarios related
                    to it.
                  </li>
                </ul>
              </li>
            </ol>
            {/* <p>{`Provide details for a new forecast scenario or edit details for selected forecast scenario. Select a forecast scenario from the “Forecast summary” in the Home page.`}</p>
            <ImageContainer fileName='addForecastScenario.png' alt='Settings' />
            <p>{`Changes you can make include adding to the number of periods forecast and changing the start date of the forecast. The model automatically creates the additional columns and changes the forecast dates according to your modifications. You have the option to bind input assumptions to a period so that if you change time periods the forecast for a certain period is still associated with that period.`}</p>
            <p>{`To add a new forecast scenario, click on “Add Forecast”. You can create a new scenario without any assumptions or copy an existing scenario and change the assumptions. The model structure will be the same across all scenarios for the selected company. Model structure includes the list of products/services, jobs/roles, costs and expenses, and fixed assets. A change to the model structure in one scenario will be reflected in all scenarios for the company.`}</p> */}
          </SectionContent>
        </SectionHeader>
        <SubSectionHeader title='Creating a Scenario'>
          <SectionContent>
            <ImageContainer fileName='add_scenario.png' alt='Add scenario' />
            <ol>
              <li>
                Use 'Add/Enable Forecast Scenario':
                <ul>
                  <li>
                    To create a new scenario, click on 'Add/Enable Forecast
                    Scenario.' This option is typically available on the 'Manage
                    Scenarios' tab.
                  </li>
                </ul>
              </li>
              <li>
                Fill in Required Fields:
                <ul>
                  <li>
                    When creating a scenario, you'll need to provide the
                    following information:
                    <ul>
                      <li>
                        <b>Scenario Name:</b> Enter a descriptive name for the
                        scenario, making it easy to identify.
                      </li>
                      <li>
                        <b>Description:</b> Add a brief description that
                        outlines the purpose or key details of the scenario.
                      </li>
                      <li>
                        <b> Start Month:</b> Specify the starting month for the
                        scenario. This indicates when the forecast period
                        begins.
                      </li>
                      <li>
                        <b> Forecast Period:</b> Define the duration of the
                        forecast period, which could be in months based on your
                        financial planning needs.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Save the Scenario:
                <ul>
                  <li>
                    After filling in the required fields, save the new scenario.
                    This action adds the scenario to the list associated with
                    the selected forecast.
                  </li>
                </ul>
              </li>
              <li>
                Scenario Limitations:
                <ul>
                  <li>
                    The number of scenarios you can create depends on your
                    subscription plan and the availability of the 'Additional
                    Scenario/s' add-on.
                  </li>
                </ul>
              </li>
            </ol>
          </SectionContent>
        </SubSectionHeader>
      </React.Fragment>
    </React.Fragment>
  );
};
