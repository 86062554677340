import React from "react";
import { SubSectionHeader } from "../../../../common/SubSectionHeader";
import { SectionContent } from "../../../../common/SectionContent";
import { ImageContainer } from "../../../../common/ImageContainer";

export const StockRepurchase = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='STOCK REPURCHASE' id='stockrepurchase'>
        <SectionContent>
          <p>
            The stock repurchase feature in Seqnc provides a powerful tool for
            managing the buyback of company shares, with support for two
            accounting methods: Cash Method and Retire Stock Method. Below are
            guidelines on how to leverage these methods effectively:
          </p>
          <b>Adding a Stock Repurchase:</b>
          <ol type='1'>
            <li>
              Navigate to the 'Stock Repurchase' section in the application.
            </li>
            <li>Click on the 'Add stock repurchase' button.</li>
            <li>
              Specify the amount of stock you intend to repurchase and the
              commencement date of the repurchase program.
            </li>
          </ol>
          <b>Accounting Methods:</b>
          <ol type='1'>
            <li>
              <i>Cash Method:</i>
              <ul>
                This accounting method reflects the immediate impact of the
                stock repurchase on the company's cash position. The repurchased
                shares are retired, and the cash spent is deducted from the
                company's available funds.
              </ul>
            </li>
            <li>
              <i> Retire Stock Method:</i>
              <ul>
                Under this method, the repurchased shares are considered
                retired, reducing the total outstanding shares. This method may
                have implications on earnings per share (EPS) and is suitable
                for companies looking to enhance shareholder value through
                reduced dilution.
              </ul>
            </li>
          </ol>
          <ImageContainer fileName='addStock.png' alt='Stock Repurchase' />
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
