import React from "react";

type Props = { children: any };

export const BulletList = (props: Props) => {
  return (
    <React.Fragment>
      <li style={{ marginLeft: "40px", marginBottom: "10px" }}>
        {props.children}
      </li>
    </React.Fragment>
  );
};
