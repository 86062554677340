import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";
import { BulletList } from "../../../common/BulletList";
import { SubSectionHeader } from "../../../common/SubSectionHeader";

export const ConnectToOtherApplications = () => {
  return (
    <React.Fragment>
      <React.Fragment>
        <SectionHeader title='CONNECT TO OTHER APPLICATIONS'>
          <SectionContent>
            <p></p>
            <ImageContainer
              fileName='connectToOtherApplications.png'
              alt='Connect To Other Applications'
            />
            <p>
              Seamlessly integrate your real-time accounting applications with
              Seqnc to enhance the accuracy and efficiency of your financial
              forecasting. Follow these steps to establish a connection and
              authorize data access.
            </p>
            <b>Steps to Connect:</b>
            <ol type='1'>
              <li>
                Access 'Connect to Other Applications':
                <ul>
                  <li>
                    Navigate to the 'Connect to Other Applications' section
                    under 'Settings' in Seqnc.
                  </li>
                </ul>
              </li>
              <li>
                Supported Accounting Applications:
                <ul>
                  <li>
                    Supported accounting applications for integration include
                    QuickBooks, XERO and Zoho Books. Ensure you are using one of
                    these platforms.
                  </li>
                </ul>
              </li>
              <li>
                Select Data Source:
                <ul>
                  <li>
                    Choose the desired accounting application as the data
                    source. Options include QuickBooks/XERO/Zoho Books.
                  </li>
                </ul>
              </li>
              <li>
                Click 'Authorize':
                <ul>
                  <li>
                    Click the 'Authorize' button to initiate the authorization
                    process.
                  </li>
                </ul>
              </li>
              <li>
                Follow Authorization Steps:
                <ul>
                  <li>
                    A new window will appear with steps for authorization.
                    Follow the on-screen instructions to connect Seqnc with your
                    accounting application.
                  </li>
                </ul>
              </li>
              <li>
                Mapping Data Source Line Items:
                <ul>
                  <li>
                    Upon successful authorization, map the data source line
                    items with Seqnc line items. This mapping is crucial for
                    generating accurate forecast-to-actual reports and rolling
                    forecast reports.
                  </li>
                </ul>
              </li>
            </ol>
            <b>Authorization Benefits:</b>
            <ol type='1'>
              <li>
                Enhanced Forecast Accuracy:
                <ul>
                  <li>
                    The authorized connection allows Seqnc to fetch real-time
                    data from your accounting application, ensuring that
                    forecasts are based on the most up-to-date financial
                    information.
                  </li>
                </ul>
              </li>
              <li>
                Streamlined Reporting:
                <ul>
                  <li>
                    By mapping data source line items with Seqnc line items, you
                    create a seamless link between your accounting application
                    and Seqnc. This linkage facilitates the generation of
                    comprehensive reports.
                  </li>
                </ul>
              </li>
            </ol>
            <b>Important Considerations:</b>
            <ol type='1'>
              <li>
                Supported Applications:
                <ul>
                  <li>
                    Confirm that you are using either QuickBooks/XERO/Zoho
                    Books, as these are the supported accounting applications
                    for integration with Seqnc.
                  </li>
                </ul>
              </li>
              <li>
                Mapping Accuracy:
                <ul>
                  <li>
                    Ensure accurate mapping of data source line items to Seqnc
                    line items to guarantee the reliability of forecast reports.
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              By connecting your real-time accounting applications with Seqnc,
              you unlock the potential for more precise and timely financial
              forecasting, contributing to informed decision-making and
              strategic planning.
            </p>
          </SectionContent>
        </SectionHeader>
      </React.Fragment>
    </React.Fragment>
  );
};
