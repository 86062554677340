import React from "react";
import { SectionHeader } from "../../common/SectionHeader";
import { SectionContent } from "../../common/SectionContent";

export const Signup = () => {
  return (
    <React.Fragment>
      <SectionHeader title='CREATE AN ACCOUNT'>
        <SectionContent>
          <p>
            If you wish to create a new account, follow these steps by clicking
            on "Create an Account" on the login page:
          </p>
          <ol type='1'>
            <li>
              Navigate to Login Page:
              <ul>
                <li>
                  Access the{" "}
                  <a href='http://app.seqnc.com' target='_blank'>
                    Seqnc login page.
                  </a>
                </li>
              </ul>
            </li>
            <li>
              Click on 'Create an Account':
              <ul>
                <li>Locate and click on the "Create an Account" button.</li>
              </ul>
            </li>
            <li>
              Provide Email and Password:
              <ul>
                <li>
                  Enter a valid email address and set a password following the
                  specified criteria.
                </li>
              </ul>
            </li>
            <li>
              Select Subscription Plan:
              <ul>
                <li>
                  Choose your preferred subscription plan (trial or paid).
                </li>
              </ul>
            </li>
            <li>
              For Paid Plan, Provide Card Details:
              <ul>
                <li>
                  If opting for a paid plan, securely enter the necessary
                  credit/debit card details.
                </li>
              </ul>
            </li>
            <li>
              Click on 'Sign Up':
              <ul>
                <li>
                  Complete the signup process by clicking the 'Sign Up' button.
                </li>
              </ul>
            </li>
            <li>
              Email Validation:
              <ul>
                <li>
                  Verify your account by confirming the validation email sent to
                  the provided email address.
                </li>
              </ul>
            </li>
            <li>
              Successful Signup Redirect:
              <ul>
                <li>
                  Upon successful signup, you will be redirected to the onboard
                  flow for further setup.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            By initiating the signup process through the "Create an Account"
            option on the login page, you can seamlessly create a new Seqnc
            account and proceed with setting up your company and financial
            model.
          </p>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
