import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";
import GoToLink1 from "../../../common/GoToLink1";

export const BSAssumptions = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title="BALANCE SHEET ASSUMPTIONS">
        <SectionContent>
          <table>
            <tr>
              <td>
                <b>Balance sheet input</b>
              </td>
              <td>
                <p>{`In the balance sheet input rows of the spreadsheet, you can provide direct inputs for the expected changes in the following balance sheet items.`}</p>
                <ul typeof="i">
                  <li>{`Change in Other current assets`}</li>
                  <li>{`Change in Long Term Investments`}</li>
                  <li>{`Change in Other Non-Current Assets`}</li>
                  <li>{`Change in Other Current Liabilities`}</li>
                  <li>{`Change in Other Non-Current Liabilities`}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <b>Cash, dividends and tax assumptions</b>
              </td>
              <td>
                {`This is the spreadsheet input section for Cash, dividends and
                tax assumptions.`}
              </td>
            </tr>
          </table>
          <p>
            <GoToLink1
              goToLink="/app/bs-sheet"
              goToText="Balance Sheet and other"
              parentSection="Input assumptions/ Input spreadsheet"
            />
          </p>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
