import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";
import BulkAddNote from "../../../common/BulkAddNote";
import GoToLink from "../../../common/GoToLink";

export const InventoryPurchase = () => {
  return (
    <React.Fragment>
      <SectionHeader title="INVENTORY PURCHASES">
        <SectionContent>
          The Inventory Purchase feature in our finance document system allows
          you to efficiently manage and track your inventory acquisitions. This
          functionality streamlines the process of recording, updating, and
          deleting inventory purchases.
          <p>
            <GoToLink
              goToLink="/app/inventory-purchases"
              goToText="inventory purchases"
              lineItemName="inventory purchase"
            />
          </p>
          <ImageContainer fileName="inventories.png" alt="Inventories" />
          <ImageContainer fileName="add-inventory.png" alt="Inventory table" />
          <BulkAddNote lineItemName="Inventory items" />
          <p>
            To access the Inventory Purchase module:
            <ol>
              <li>
                Navigate to the "Create Model" section in the left navigation
                menu.
              </li>
              <li>Look for and select "Inventory Purchases."</li>
            </ol>
          </p>
          Upon accessing the Inventory Purchases module, you will encounter a
          summary table displaying essential information related to your
          inventory acquisitions.
          <p>
            When creating an inventory purchase, you will encounter the
            following input fields:
            <ol>
              <li>
                <b>Name: </b>Provide a name for the inventory purchase.
              </li>
              <li>
                <b>A/P Days for This Inventory Purchase: </b>Specify the
                accounts payable days associated with this particular inventory
                purchase.
              </li>
            </ol>
          </p>
          <p>
            <i> Adding Inventory:</i>
            <br />
            To include items in your inventory purchase:
            <ol>
              <li>Click on the "Add Inventory Item" button.</li>
              <li>Fill in the relevant details for each inventory item.</li>
            </ol>
          </p>
          <p>
            <i>Updating or Deleting:</i>
            <br />
            To make modifications or remove an inventory item:
            <ol>
              <li>
                Locate the respective inventory item in the summary table.
              </li>
              <li>Utilize the edit icon associated with the item.</li>
              <li>
                Choose between updating or deleting the inventory item based on
                your requirements.
              </li>
            </ol>
          </p>
          <p>
            Input Assumptions:
            <ol>
              <li>
                Navigate to the 'Input Spreadsheet' under the 'Input
                Assumptions' section in the left navigation menu.
              </li>
              <li>
                Enter assumptions and details for each inventory purchase,
                considering monthly variations in the "Inventory purchases"
                sheet.
              </li>
            </ol>
          </p>
          This streamlined process ensures that your inventory purchase records
          are accurate and up-to-date, providing you with better control and
          visibility into your financial transactions.
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
