import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

type PropTypes = {
  className?: string;
  id: string;
  path: string;
  displayName: string;
  currentLink: string;
  setCurrentLink: Function;
};

export const NavLink = (props: PropTypes) => {
  const { className, id, path, displayName, currentLink, setCurrentLink } =
    props;

  const location = useLocation();

  useEffect(() => {
    if (path && location.pathname && path === location.pathname) {
      setCurrentLink(id);
    }
  }, [path, id, location]);

  const [activeClass, setActiveClass] = useState("");

  useEffect(() => {
    const activeClass = currentLink === id ? "menu-item-active" : "";
    setActiveClass(activeClass);
  }, [currentLink]);

  return (
    <Link
      className={`sq-sub-nav-link  ${className} ${activeClass}`}
      id={id}
      to={path ? path : "/"}
      onClick={() => {
        setCurrentLink(id);
      }}
    >
      {displayName}
      {/* <span className='sq-snl-tick' />
      <span className='sq-snl-dot' /> */}
    </Link>
  );
};
