import React from "react";
import PropTypes from "prop-types";
import GoToLink from "./GoToLink";

type Props = {
  parentSection: string;
  goToLink: string;
  goToText: string;
};
function GoToLink1(props: Props) {
  const { parentSection, goToLink, goToText } = props;
  return (
    <span>
      Under {parentSection} section click on{" "}
      <GoToLink goToText={goToText} goToLink={goToLink} linkOnly /> to access
      this page
    </span>
  );
}

export default GoToLink1;
