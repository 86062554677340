import React from "react";
import { SectionHeader } from "../../common/SectionHeader";
import { SectionContent } from "../../common/SectionContent";
import { ImageContainer } from "../../common/ImageContainer";

export const TrackPerformance = () => {
  return (
    <React.Fragment>
      <SectionHeader title='TRACK PERFORMANCE'>
        <SectionContent>
          <p>{`Track performance helps to configure the actual data source and view the forecast to actual and rolling forecast reports`}</p>
          <ImageContainer
            fileName='trackPerformance.png'
            alt='Track performance'
          />
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
