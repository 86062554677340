import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";

export const Department = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='DEPARTMENT'>
        <SectionContent>
          <p>
            The Departments feature in your Seqnc finance document empowers you
            to create a hierarchical structure for efficient organization. This
            hierarchy is utilized by both "Job/Roles" and "Cost and Expenses"
            sections. Categories are specifically tailored to different cost
            types, such as Direct Costs and Operating Expenses.
          </p>
          <ImageContainer fileName='department.png' alt='Department' />
          <p>
            Accessing and Managing Categories:
            <ol>
              <li>
                Navigate to the "Create Model" section in the left navigation
                menu.
              </li>
              <li>
                Find the "Job/Roles & Department" or "Costs & Expenses" module.
              </li>
              <li>
                Utilize the tree component to manage your departmental
                hierarchy.
              </li>
            </ol>
          </p>
          <p>
            Adding a Department:
            <ol>
              <li>Right-click on any department line item in the tree.</li>
              <li>
                Select the "Add Category/Department" option to include a new
                department in the hierarchy.
              </li>
            </ol>
          </p>
          <p>
            Updating/Delete Department:
            <ol>
              <li>Right-click on a department line item.</li>
              <li>
                Choose the "Edit" option to update or delete the department.
              </li>
              <li>
                Utilize the drag-and-drop functionality to seamlessly reorganize
                line items within the tree hierarchy.
              </li>
            </ol>
          </p>
          <p>
            Deleting a department prompts two options:
            <ul>
              <li>
                <i> Delete Category/Department Label Only: </i>All line items in
                the category/department will be reclassified as uncategorized.
              </li>
              <li>
                <i>
                  Delete Category/Department Label and All Line Items Under the
                  Category/Department:{" "}
                </i>
                Removes both the category/department label and all associated
                line items.
              </li>
            </ul>
          </p>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
