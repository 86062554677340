import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { CostAndExpenses } from "./components/pages/createModel/CostAndExpenses/CostAndExpenses";
import { CreateModel } from "./components/pages/createModel/CreateModel";
import { FixedAssets } from "./components/pages/createModel/FixedAssets/FixedAssets";
import { FormatViewSettings } from "./components/pages/createModel/FormatView/FormatViewSettings";
import { InventoryPurchase } from "./components/pages/createModel/InventoryPurchases/InventoryPurchase";
import { JobRolesDepartments } from "./components/pages/createModel/JobRolesDepartments/JobRolesDepartments";
import { ProductAndServices } from "./components/pages/createModel/Product/ProductAndServices";
import { GeneralAssumptions } from "./components/pages/inputAssumptions/GeneralAssumptions";
import { InputAssumptions } from "./components/pages/inputAssumptions/InputAssumptions";
import { InputSpreadSheet } from "./components/pages/inputAssumptions/inputSpreadSheet/InputSpreadSheet";
import { OtherAssumptions } from "./components/pages/inputAssumptions/otherAssumptions/OtherAssumptions";
import { Allocations } from "./components/pages/inputAssumptions/otherAssumptions/allocations/Allocations";
import { CapitalPlan } from "./components/pages/inputAssumptions/otherAssumptions/capitalPlan/CapitalPlan";
import { StartingBalanceSheet } from "./components/pages/inputAssumptions/otherAssumptions/sbs/StartingBalanceSheet";
import { Introduction } from "./components/pages/introduction/Introduction";
import { Modules } from "./components/pages/modules/Modules";
import { ConnectToOtherApplications } from "./components/pages/modules/connectToOtherApplications/ConnectToOtherApplications";
import { CollaborationAndCopies } from "./components/pages/modules/copies/CollaborationAndCopies";
import { Forecasts } from "./components/pages/modules/forecasts/Forecasts";
import { Workspace } from "./components/pages/modules/workspace/Workspace";
import { Onboard } from "./components/pages/onboard/Onboard";
import { BillingRevenues } from "./components/pages/reports/BillingRevenues";
import { CapitalRequired } from "./components/pages/reports/CapitalRequired";
import { FinancialProjections } from "./components/pages/reports/FinancialProjections";
import { Reports } from "./components/pages/reports/Reports";
import { ScenarioComparision } from "./components/pages/reports/ScenarioComparision";
import { SubscriptionMetrics } from "./components/pages/reports/SubscriptionMetrics";
import { Summary } from "./components/pages/reports/Summary";
import { ManageAccount } from "./components/pages/settings/ManageAccount";
import { ManageTeam } from "./components/pages/settings/ManageTeam";
import { Signup } from "./components/pages/signup/Signup";
import { ConfigureDataSource } from "./components/pages/trackPerformance/ConfigureDataSource";
import { F2A } from "./components/pages/trackPerformance/F2A";
import { RollingForecast } from "./components/pages/trackPerformance/RollingForecast";
import { TrackPerformance } from "./components/pages/trackPerformance/TrackPerformance";
import { WatchWindow } from "./components/pages/watchWindow/WatchWindow";
import { AppContainer } from "./container/AppContainer";
import { AppProvider } from "./global/AppContext";
import AppContextData from "./global/AppContextData";
import { URLPart } from "./util/Path";

function App() {
  const appContextData = new AppContextData();

  return (
    <div>
      <AppProvider value={appContextData}>
        <BrowserRouter>
          <Routes>
            <Route path={URLPart.CONTENT}>
              <Route index element={<Introduction />} />
              <Route path={URLPart.SIGNUP} element={<Signup />} />
              <Route path={URLPart.ONBOARD} element={<Onboard />} />
              <Route path={URLPart.MODULES}>
                <Route index element={<Modules />} />
                <Route path={URLPart.WORKSPACE} element={<Workspace />} />
                <Route path={URLPart.FORECASTS} element={<Forecasts />} />
                <Route
                  path={URLPart.COLLABORATION_COPIES}
                  element={<CollaborationAndCopies />}
                />
                <Route
                  path={URLPart.CONNECT_TO_OTHER_APPLICATIONS}
                  element={<ConnectToOtherApplications />}
                />
                <Route
                  path={URLPart.MANAGE_ACCOUNT}
                  element={<ManageAccount />}
                />
                <Route path={URLPart.MANAGE_TEAM} element={<ManageTeam />} />
              </Route>
              <Route path={URLPart.CREATE_MODEL}>
                <Route index element={<CreateModel />} />
                <Route
                  path={URLPart.PRODUCT_SERVICES}
                  element={<ProductAndServices />}
                />
                <Route
                  path={URLPart.JOB_ROLES}
                  element={<JobRolesDepartments />}
                />
                <Route path={URLPart.EXPENSES} element={<CostAndExpenses />} />
                <Route
                  path={URLPart.INVENTORIES}
                  element={<InventoryPurchase />}
                />
                <Route path={URLPart.FIXED_ASSETS} element={<FixedAssets />} />
                <Route
                  path={URLPart.FORMAT_VIEW}
                  element={<FormatViewSettings />}
                />
              </Route>
              <Route path={URLPart.INPUT_ASSUMPTIONS}>
                <Route index element={<InputAssumptions />} />
                <Route
                  path={URLPart.GENERAL_ASSUMPTIONS}
                  element={<GeneralAssumptions />}
                />
                <Route
                  path={URLPart.INPUT_SPREADSHEET}
                  element={<InputSpreadSheet />}
                />
                <Route path={URLPart.OTHER_ASSUMPTIONS}>
                  <Route index element={<OtherAssumptions />} />
                  <Route
                    path={URLPart.STARTING_BS_SHEET}
                    element={<StartingBalanceSheet />}
                  />
                  <Route
                    path={URLPart.CAPITAL_PLAN}
                    element={<CapitalPlan />}
                  />
                  <Route path={URLPart.ALLOCATIONS} element={<Allocations />} />
                </Route>
              </Route>
              <Route path={URLPart.REPORTS}>
                <Route index element={<Reports />} />
                <Route
                  path={URLPart.FINANCIAL_PROJECTIONS}
                  element={<FinancialProjections />}
                />
                <Route
                  path={URLPart.BILLING_AND_REVENUES}
                  element={<BillingRevenues />}
                />
                <Route
                  path={URLPart.CAPITAL_REQUIRED}
                  element={<CapitalRequired />}
                />
                <Route
                  path={URLPart.SUBSCRIPTION_METRICS}
                  element={<SubscriptionMetrics />}
                />
                <Route path={URLPart.SUMMARY} element={<Summary />} />
                <Route
                  path={URLPart.SCENARIO_COMPARISION}
                  element={<ScenarioComparision />}
                />
              </Route>
              <Route path={URLPart.TRACK_PERFORMANCE}>
                <Route index element={<TrackPerformance />} />
                <Route
                  path={URLPart.CONFIGURE_DATASOURCE}
                  element={<ConfigureDataSource />}
                />
                <Route path={URLPart.FORECAST_TO_ACTUAL} element={<F2A />} />
                <Route
                  path={URLPart.ROLLING_FORECAST}
                  element={<RollingForecast />}
                />
              </Route>
              <Route path={URLPart.WATCH_WINDOW} element={<WatchWindow />} />
            </Route>
            <Route path={URLPart.HELP} element={<AppContainer />}>
              <Route index element={<Introduction />} />
              <Route path={URLPart.SIGNUP} element={<Signup />} />
              <Route path={URLPart.ONBOARD} element={<Onboard />} />
              <Route path={URLPart.MODULES}>
                <Route index element={<Modules />} />
                <Route path={URLPart.FORECASTS} element={<Forecasts />} />
                <Route
                  path={URLPart.COLLABORATION_COPIES}
                  element={<CollaborationAndCopies />}
                />
                <Route path={URLPart.WORKSPACE} element={<Workspace />} />
                <Route
                  path={URLPart.CONNECT_TO_OTHER_APPLICATIONS}
                  element={<ConnectToOtherApplications />}
                />
                <Route
                  path={URLPart.MANAGE_ACCOUNT}
                  element={<ManageAccount />}
                />
                <Route path={URLPart.MANAGE_TEAM} element={<ManageTeam />} />
              </Route>
              <Route path={URLPart.CREATE_MODEL}>
                <Route index element={<CreateModel />} />
                <Route
                  path={URLPart.PRODUCT_SERVICES}
                  element={<ProductAndServices />}
                />
                <Route
                  path={URLPart.JOB_ROLES}
                  element={<JobRolesDepartments />}
                />
                <Route path={URLPart.EXPENSES} element={<CostAndExpenses />} />
                <Route
                  path={URLPart.INVENTORIES}
                  element={<InventoryPurchase />}
                />
                <Route path={URLPart.FIXED_ASSETS} element={<FixedAssets />} />
                <Route
                  path={URLPart.FORMAT_VIEW}
                  element={<FormatViewSettings />}
                />
              </Route>
              <Route path={URLPart.INPUT_ASSUMPTIONS}>
                <Route index element={<InputAssumptions />} />
                <Route
                  path={URLPart.GENERAL_ASSUMPTIONS}
                  element={<GeneralAssumptions />}
                />
                <Route
                  path={URLPart.INPUT_SPREADSHEET}
                  element={<InputSpreadSheet />}
                />
                <Route path={URLPart.OTHER_ASSUMPTIONS}>
                  <Route index element={<OtherAssumptions />} />
                  <Route
                    path={URLPart.STARTING_BS_SHEET}
                    element={<StartingBalanceSheet />}
                  />
                  <Route
                    path={URLPart.CAPITAL_PLAN}
                    element={<CapitalPlan />}
                  />
                  <Route path={URLPart.ALLOCATIONS} element={<Allocations />} />
                </Route>
              </Route>
              <Route path={URLPart.REPORTS}>
                <Route index element={<Reports />} />
                <Route
                  path={URLPart.FINANCIAL_PROJECTIONS}
                  element={<FinancialProjections />}
                />
                <Route
                  path={URLPart.BILLING_AND_REVENUES}
                  element={<BillingRevenues />}
                />
                <Route
                  path={URLPart.CAPITAL_REQUIRED}
                  element={<CapitalRequired />}
                />
                <Route
                  path={URLPart.SUBSCRIPTION_METRICS}
                  element={<SubscriptionMetrics />}
                />
                <Route path={URLPart.SUMMARY} element={<Summary />} />
                <Route
                  path={URLPart.SCENARIO_COMPARISION}
                  element={<ScenarioComparision />}
                />
              </Route>
              <Route path={URLPart.TRACK_PERFORMANCE}>
                <Route index element={<TrackPerformance />} />
                <Route
                  path={URLPart.CONFIGURE_DATASOURCE}
                  element={<ConfigureDataSource />}
                />
                <Route path={URLPart.FORECAST_TO_ACTUAL} element={<F2A />} />
                <Route path={URLPart.ACTUAL_BREAKUP} element={<F2A />} />
                <Route
                  path={URLPart.ROLLING_FORECAST}
                  element={<RollingForecast />}
                />
              </Route>
              <Route path={URLPart.WATCH_WINDOW} element={<WatchWindow />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AppProvider>
    </div>
  );
}

export default App;
