import React from "react";
import { ImageContainer } from "../../common/ImageContainer";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";

export const Reports = () => {
  return (
    <React.Fragment>
      <SectionHeader title='VIEW REPORTS'>
        <SectionContent>
          <p>
            Within the Reports section, you'll find detailed model outputs
            covering key aspects. These encompass financial statements, billings
            and revenues, capital requirements, subscription metrics, and a
            summarized overview, with an additional option for scenario
            comparison. Conveniently, all reports are available for download in
            Microsoft Excel formats.
          </p>
          <ImageContainer fileName='reports.png' alt='Reports' />
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
