import React from "react";
import { SectionHeader } from "../../../../common/SectionHeader";
import { SectionContent } from "../../../../common/SectionContent";
import { SubSectionHeader } from "../../../../common/SubSectionHeader";
import { PriorDebt } from "./PriorDebt";
import { PriorFixedAssets } from "./PriorFixedAssets";
import { ImageContainer } from "../../../../common/ImageContainer";

export const StartingBalanceSheet = () => {
  return (
    <React.Fragment>
      <SectionHeader title='STARTING BALANCE SHEET'>
        <SectionContent>
          <p>
            In this section, you can input assumptions related to the starting
            balance sheet. This encompasses methods for specifying or
            calculating ongoing costs, drawdown or amortization of starting
            values, and common assumptions for cash, dividends, and tax.
          </p>
          <ImageContainer fileName='sbs.png' alt='Starting Balance Sheet' />
        </SectionContent>
      </SectionHeader>
      <SubSectionHeader
        title='STARTING BALANCE SHEET'
        id='startingBalanceSheet'
      >
        <SectionContent>
          <p>
            Enter values for your starting balance sheet at the beginning of the
            forecast. As you fill out the required fields, Seqnc will
            automatically adjust the balance sheet over time according to your
            assumptions. Note that providing a specific starting balance sheet
            is optional; if you choose not to input values, the model will
            default to a zero starting balance sheet.
          </p>
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader
        title='SETTINGS FOR AMOUNTS IN STARTING BALANCE SHEET'
        id='sbsSettings'
      >
        <SectionContent>
          <p>
            For Accounts Receivable, Accounts Payable, and intangible assets,
            you have the option to specify the drawdown or amortization of
            values in the starting balance sheet for these line items, if
            applicable. If you don't have specific custom requirements, you can
            also choose default settings.
          </p>
          <ImageContainer
            fileName='sbsSettings.png'
            alt='Starting Balance Sheet'
          />
        </SectionContent>
      </SubSectionHeader>
      <PriorDebt />
      <PriorFixedAssets />
    </React.Fragment>
  );
};
