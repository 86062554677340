import React from "react";
import { SubSectionHeader } from "../../../../common/SubSectionHeader";
import { SectionContent } from "../../../../common/SectionContent";
import { ImageContainer } from "../../../../common/ImageContainer";

export const LOCDebt = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='LOC DEBT' id='locdebt'>
        <SectionContent>
          <p>
            With Seqnc, you have the flexibility to incorporate one or more
            lines of credit as a funding source to fulfill your capital
            requirements. To add a line of credit, provide details such as the
            available amount, the date it becomes available, and the interest
            rate associated with the line. Seqnc automatically draws down the
            available amount and incorporates the relevant cost of debt into the
            Profit and Loss (P&L). In the case of multiple lines, the available
            amount will be the total of all lines on the specified date.
          </p>
          <ImageContainer fileName='loc.png' alt='LOC Debt' />
          <p>
            The paydown amount in any period is automatically calculated based
            on the available cash surplus after meeting operating capital
            requirements and minimum cash balance, if applicable. You can opt
            for 'No paydown' if you do not intend to use available cash to
            reduce any outstanding revolver balance in a given period."
          </p>
          <ImageContainer fileName='addLOC.png' alt='LOC Debt' />
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
