import React from "react";
import { SubSectionHeader } from "../../common/SubSectionHeader";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";
import { ImageContainer } from "../../common/ImageContainer";
import GoToLink from "../../common/GoToLink";

export const GeneralAssumptions = () => {
  return (
    <React.Fragment>
      <SectionHeader title="GENERAL ASSUMPTIONS">
        <SectionContent>
          <ImageContainer
            fileName="generalAssumptions.png"
            alt="General Assumptions"
          />
          <p>
            Under Input assumptios section click on{" "}
            <GoToLink
              goToLink="/app/general-assumptions"
              goToText="General Assumptions"
              linkOnly
            />
            &nbsp; to access this page.
            {` Below is the list of settings in this section`}
          </p>
          <table>
            <tr>
              <td>
                <b>Starting cash</b>
              </td>
              <td>
                <>
                  <p>{`Select to either specify only starting cash balance at beginning of forecast or a full starting balance sheet`}</p>
                  <p>Options:</p>
                  <p>
                    {`“Specify starting cash only”: Select this option if you
                    don’t want to fill entire starting balance sheet`}
                  </p>
                  <p>{`“Provide full starting balance sheet”: Complete the starting balance sheet template that is provided in the Assumptions section`}</p>
                </>
              </td>
            </tr>
            <tr>
              <td>
                <b>Minimum cash balance</b>
              </td>
              <td>
                <>
                  <p>{`Select how you want to specify a preferred min cash amount in the forecast period. Preferred minimum cash balance is applied when surplus cash is available. If not, cash balance may fall below the preferred minimum, or become negative if there’s a capital shortfall.`}</p>
                  <p>Options:</p>
                  <p>{`“Specific minimum cash balance amount”: Enter amount without a currency symbol`}</p>
                  <p>{`“Min cash balance % of total costs in period”: Enter value without % sign; for example, enter 15.5 for 15.5%`}</p>
                </>
              </td>
            </tr>
            <tr>
              <td>
                <b>Interest earned on cash balance</b>
              </td>
              <td>{`Interest earned on cash balance`}</td>
            </tr>
            <tr>
              <td>
                <b>Effective tax rate</b>
              </td>
              <td>{`Effective tax rate. Enter value without % sign; for example, enter 15.5 for 15.5%`}</td>
            </tr>
            <tr>
              <td>
                <b>Do you plan to pay dividends?</b>
              </td>
              <td>{`If yes, model will add a row in the input spreadsheet where you can enter dividend assumptions by period`}</td>
            </tr>
            <tr>
              <td>
                <b>
                  Do you want to specify Net Operating Loss (NOL) carry
                  forwards?
                </b>
              </td>
              <td>{`If yes, model will add a row in the input spreadsheet where you can enter NOL assumptions by period`}</td>
            </tr>
          </table>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
