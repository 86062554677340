import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";
export const CurrentCustomers = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='CURRENT CUSTOMERS'>
        <SectionContent>
          {`A subscription/SaaS product you add to the forecast may already have customers and you will need to include the ongoing and renewal revenues from your existing customer/installed base. Seqnc automates these calculations and provides options for how you would like to add such revenues to the model.`}
          <table>
            <tr>
              <td>{`Number of current customers/users`}</td>
              <td>{`Enter the number of customers you have now. If you don’t have customers yet then you can enter zero for this field`}</td>
            </tr>
            <tr>
              <td>{`Ongoing renewal revenues from installed base`}</td>
              <td>
                <>
                  {`For subscription/SaaS product, you can specify how to include revenues from renewals of installed base/current customers. You can select from the following options:`}
                  <br />
                  {`(i)                   “Specify number of customers scheduled for renewal during each period”: Input spreadsheet will be generated with a row for you to provide this assumption`}
                  <br />
                  {`(ii)                “Calculate based prior growth rate”: You need to input prior growth rate in the field provided and ongoing revenues will be calculated by system.`}
                  <br />
                  {`(iii)               “Specify ongoing invoice amounts (billings) for installed base`}
                  <br />
                  {`Add an input row in the spreadsheet for this assumption: Select “Yes” if you want to enter period wise data for this assumption. Select “No” if you don’t need this input (Value will be set=0 in this case)`}
                </>
              </td>
            </tr>
            <tr>
              <td>{`Revenues recognized from prior deferred revenues`}</td>
              <td>
                <>
                  {`If you have deferred revenues in the balance sheet, you can select how you would like to recognize the revenues during the forecast period.  For “Is any of prior deferred revenues attributed to this product?” select “Yes” if deferred revenues applicable. Select “No” if not applicable.  `}
                  <br />
                  <b>
                    “Method for recognizing prior deferred revenues(select an
                    option)”:
                  </b>
                  {`  Option “Specify amount of deferred revenues to be recognized in each period”  will be selected by default. To  control  whether to add an input row in spreadsheet for this assumption , select “Yes” option for “Add an input row in the spreadsheet for this assumption.” field`}
                </>
              </td>
            </tr>
          </table>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
