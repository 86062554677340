import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";
import GoToLink1 from "../../../common/GoToLink1";

export const Costs = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title="DIRECT/OPEX COSTS">
        <SectionContent>
          <p>
            The 'Direct/Opex Costs' spreadsheet is where you input assumptions
            for each cost defined in the 'Model Setup.' Each cost item is
            represented as a row in this sheet. If you add new direct/opex cost
            line items in the 'Model Setup,' they will be automatically
            displayed as new rows in this sheet. Conversely, any cost you delete
            from the 'Model Setup' will no longer appear in the list for input
            assumptions in this spreadsheet. This dynamic integration ensures
            that the input assumptions align seamlessly with the defined cost
            structure, facilitating accurate financial modeling.
          </p>
          <GoToLink1
            goToLink="/app/direct-costs-sheet"
            goToText="Direct costs"
            parentSection="Input assumptions/Input spreadsheet"
          />
          <ImageContainer fileName="directExpense.png" alt="Direct Costs" />
          <br />
          <br />
          <GoToLink1
            goToLink="/app/opex-costs-sheet"
            goToText="Operating expenses"
            parentSection="Input assumptions/Input spreadsheet"
          />
          <ImageContainer
            fileName="operatingExpense.png"
            alt="Operating Expenses"
          />
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
