import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";

export const Pricing = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='PRICING'>
        <SectionContent>
          {`The Seqnc model generates pricing input rows based on the pricing model you select for this product or service. (Input sheets are in the “Model Input” section.)`}
          <br />
          {`The pricing model is the way in which you price this product or service for your customers. Four pricing models are predefined or you can specify a price per booking as a custom pricing row. For each option, the model will apply the fields that are applicable; the following tables provide information on the fields that are displayed for each pricing model. You can change a pricing model option after your initial selection; in that case, you would need to provide assumptions/inputs for any new rows for the selected pricing model. You may also need to review any formula links that may have been invalidated due to the change.`}
          <br />
          <i>Note:</i>
          {`Input rows will be generated in spreadsheet only if you have selected “Vary value by month” for Pricing trend. If you have selected “Use same value for all months” for pricing trend then the input you entered in the form will be used for all time periods.`}
          {``}
          <br />
          {`“Pricing” section fields when pricing model is “Subscription price per month”`}
          <table>
            <tr>
              <td>{`Subscription price per month`}</td>
              <td>{`Enter monthly subscription price irrespective of subscription term`}</td>
            </tr>
            <tr>
              <td>{`Average discount`}</td>
              <td>{`Average discount on price`}</td>
            </tr>
            <tr>
              <td>{`Installation/setup fees per new customer`}</td>
              <td>{`Specify one time, non recurring fees you charge a new customer, if applicable`}</td>
            </tr>
            <tr>
              <td>{`Non recurring installation/setup fees per booking`}</td>
              <td>{`Specify one time, non recurring fees you charge a new customer, if applicable`}</td>
            </tr>
          </table>
          <br />
          {`“Pricing” section fields when pricing model is “Revenue per transaction”`}
          <table>
            <tr>
              <td>{`Avg transaction value`}</td>
              <td>{`Enter the fixed amount you charge per transaction, if any`}</td>
            </tr>
            <tr>
              <td>{`Avg number of transactions per customer`}</td>
              <td>{`Enter the number of transactions per month per customer if pricing is based on transaction volume`}</td>
            </tr>
            <tr>
              <td>{`Fixed fees/transaction`}</td>
              <td>{`Enter the fixed amount you charge per transaction, if any`}</td>
            </tr>
            <tr>
              <td>{`Variable fees ( % of transaction value)`}</td>
              <td>{`Enter the variable amount you charge per transaction, if any;`}</td>
            </tr>
            <tr>
              <td>{`Average discount`}</td>
              <td>{`Average discount on price`}</td>
            </tr>
            <tr>
              <td>{`Non recurring installation/setup fees per booking`}</td>
              <td>{`Specify one time, non recurring fees you charge a new customer, if applicable`}</td>
            </tr>
          </table>
          <br />
          {`“Pricing” section fields when pricing model is “Usage based pricing (est rev/user/month)”`}
          <table>
            <tr>
              <td>{`Base price per month`}</td>
              <td>{`Enter the amount you charge for the base included usage`}</td>
            </tr>
            <tr>
              <td>{`Avg number of units of usage above base`}</td>
              <td>{`Enter the usage level above base included usage level`}</td>
            </tr>
            <tr>
              <td>{`Variable fees per unit of usage above base usage`}</td>
              <td>{`Enter the variable amount you charge per transaction, if any`}</td>
            </tr>
            <tr>
              <td>{`Average discount`}</td>
              <td>{`Average discount on price`}</td>
            </tr>
            <tr>
              <td>{`Non recurring installation/setup fees per booking`}</td>
              <td>{`Specify one time, non recurring fees you charge a new customer, if applicable;`}</td>
            </tr>
          </table>
          <br />
          {`“Pricing” section fields when pricing model is “Custom (rev per unit or contract)”`}
          <table>
            <tr>
              <td>{`Avg total price or contract value, per customer per month`}</td>
              <td>{`Enter price unit sold`}</td>
            </tr>
            <tr>
              <td>{`Average discount %`}</td>
              <td>{`Average discount on price`}</td>
            </tr>
            <tr>
              <td>{`Installation/setup fees per new customer`}</td>
              <td>{`Specify one time, non recurring fees you charge a new customer,if applicable`}</td>
            </tr>
          </table>
          <br />
          {`“Pricing” section fields when pricing model is “Price per unit”`}
          <table>
            <tr>
              <td>{`Price per unit`}</td>
              <td>{`Enter price unit sold`}</td>
            </tr>
            <tr>
              <td>{`Average discount`}</td>
              <td>{`Average discount on price`}</td>
            </tr>
            <tr>
              <td>{`Non recurring installation/setup fees per booking`}</td>
              <td>{`Specify one time, non recurring fees you charge a new customer, if applicable`}</td>
            </tr>
          </table>
          <br />
          {`“Pricing” section fields when pricing model is “Service revenue per customer/contract per month”`}
          <table>
            <tr>
              <td>{`Service revenue per customer/contract per month`}</td>
              <td>{`Enter revenue from service per customer/contract per month`}</td>
            </tr>
            <tr>
              <td>{`Average discount`}</td>
              <td>{`Average discount on price`}</td>
            </tr>
            <tr>
              <td>{`Non recurring installation/setup fees per booking`}</td>
              <td>{`Specify one time, non recurring fees you charge a new customer, if applicable`}</td>
            </tr>
          </table>
          <br />
          {`“Pricing” section fields when pricing model is “Provide billings forecast directly”`}
          <br />
          {`When this pricing method is selected user can  directly provide billing forecast number under “Revenues forecast”  row in “Sales & Pricing” sheet of input spreadsheet`}
          <br />
          <b>Churn:</b>
          {` Select churn% or churn amount. For churn%, you can use a common % or change by forecast period; For churn amount, a row will be provided in input spreadsheet for churn amount assumptions.`}
          <br />
          <b>{`Accounts Receivable: `}</b>
          {`Why we ask for this input here - Seqnc allows you to specify accounts receivables by product so we ask for the information as you define your pricing. It has 2 options`}
          <br />
          {`Account Receivable days(A/R days):  Enter the number of days you give customers to pay your invoice.`}
          <br />
          {`Specify a custom A/R schedule:  Enter the % of remaining A_R (after any prepayment) you expect to receive in each month following the original sale. Enter as a % of the remaining balance expected and ensure that total adds to 100%`}
          <br />
          <b>{`Trend preferences: `}</b>
          {`A powerful feature of the model is that it allows for changes in price, churn (if applicable), and number of users per new customer (if applicable). The option to varying these assumptions for forecast time periods lets you measure the impact of these changes over time and create scenarios for different assumptions.`}
          <br />
          <b>{`Apply custom A/R schedule for total contract value or every billing amount: `}</b>
          {`Select one of the below options`}
          <br />
          {`1.Apply on total contract amount`}
          <br />
          {`2.Apply for each billing amount`}
          <br />
          <table>
            <tr>
              <td>{`Pricing trend`}</td>
              <td>
                <>
                  {`Options:`}
                  <br />
                  {`“Use same value for all months”: Pricing you provide in this form will be used for all forecast periods.`}
                  <br />
                  {`“Vary value by month”: Pricing rows in the input spreadsheet will be enabled so you can vary price by month during the forecast period, either to show price increases/decreases or changes in transaction/usage volume`}
                </>
              </td>
            </tr>
            <tr>
              <td>{`Churn trend`}</td>
              <td>
                <>
                  {`Options:`}
                  <br />
                  {`“Use same value for all months”: Churn % you provide in this form will be used for all forecast periods.`}
                  <br />
                  {`“Vary value by month”: Input spreadsheet will be generated with a row to vary churn trend assumptions over the forecast period`}
                </>
              </td>
            </tr>
            <tr>
              <td>{`Users trend`}</td>
              <td>
                {" "}
                <>
                  {`Options:`}
                  <br />
                  {`“Use same value for all months”: Number of users per customer you specify in this form will be used for all forecast periods if you select this option. Select "Vary across periods" if you want to specify different values for different periods. A row in the input spreadsheet will be added for you to provide these assumptions.`}
                </>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td>{`Churn`}</td>
              <td>
                <>
                  {`“Net churn %”:Net churn % represents % of customers who cancel and do not change (upgrade/downgrade) to a different subscription plan`}
                  <br />
                  {`“Gross churn %”:Gross churn represents % of customers that cancel or change (upgrade/downgrade) to other products. You can describe % of customers who upgrade/downgrade to other products in the expansion revenues section`}
                </>
              </td>
            </tr>
          </table>
          <br />
          <table>
            <tr>
              <td>{`Accounts receivable`}</td>
              <td>{`Seqnc allows you to specify accounts receivables by product so we ask for the information as you define your pricing. You can choose to provide the A/R days value which will be applicable in general or define a custom schedule to specify the A/R as a % of the billing amount that is expected to be received by month after sale. If you select a custom A/R schedule, the total % of A/R by month after sale should add up to 100%.`}</td>
            </tr>
          </table>
        </SectionContent>{" "}
      </SubSectionHeader>
    </React.Fragment>
  );
};
