import { Typography } from "@mui/material";
import React from "react";
import { BulletList } from "../../common/BulletList";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";
import { ImageContainer } from "../../common/ImageContainer";
import { SubSectionHeader } from "../../common/SubSectionHeader";

export const WatchWindow = () => {
  return (
    <React.Fragment>
      <SectionHeader title='WATCH WINDOW'>
        <SectionContent>
          <p>
            {" "}
            {`In this application, the watch window is a useful tool that allows you to monitor 
          the values of key metrics while you navigate through different sections in the
          application. It helps you keep track the important data without having to constantly
          scroll or switch between UI-pages. This also helps to compare selected metrics between
          scenarios. Here's how you can use the Watch Window:`}
          </p>
          <ol type='a'>
            <BulletList>
              {`Click on the ‘Watch window’ hyper link located top-right of the
            app bar (refer following screenshot) to activate the watch window.`}
            </BulletList>
            <BulletList>
              {`The watch window is a resizable, draggable window displaying the
            key metrics for scenario/s.`}
            </BulletList>
          </ol>
          <ImageContainer fileName='watch-window.png' alt='Watch Window' />
        </SectionContent>
      </SectionHeader>
      <SubSectionHeader title='SETTINGS'>
        <SectionContent>
          <p>
            {`Using settings gear icon located on the tog-right corner of the
            watch window select the metrics and the scenario/s to display in the
            watch window.`}
          </p>
          <ImageContainer
            fileName='ww-settings.png'
            alt='Watch window settings'
          />
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
