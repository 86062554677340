import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { Collaboration } from "./Collaboration";
import { Copies } from "./Copies";
import { Col, Row } from "react-bootstrap";
import { ImageContainer } from "../../../common/ImageContainer";

export const CollaborationAndCopies = () => {
  return (
    <React.Fragment>
      <SectionHeader title='Collaboration & copies'>
        <SectionContent>
          {`Access through Settings > 'Add collaborator or share copy'`}
          <Row>
            <Col xs={6} md={3}>
              <ImageContainer
                fileName='collaboratorsCopies-nav.png'
                alt='Copies'
                style={{ height: "300px" }}
              />
            </Col>
            <Col xs={18} md={9}>
              <ImageContainer
                fileName='collaboratorsCopies.png'
                alt='Cost and Expenses'
              />
            </Col>
          </Row>
          <Collaboration />
          <Copies />
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
