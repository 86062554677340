import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { Col, Row } from "react-bootstrap";

export const Copies = () => {
  return (
    <React.Fragment>
      <SectionHeader title='Copies'>
        <SectionContent>
          <p>
            The Copies feature enables users to review the most recently updated
            model shared with them. Users have the ability to import the shared
            model without modifying the owner's original model. Any adjustments
            made by the recipient to their copy will not affect the original
            model in the owner's account. To terminate access, utilize the
            'Revoke Share' button. When the owner deletes a forecast, it
            automatically results in the revocation of access for all shared
            users. Deleting a forecast effectively terminates the shared access
            for all users who were previously granted access to that specific
            forecast. It's important to note that any copies imported by the
            recipient prior to access revocation will remain accessible to them.
          </p>
        </SectionContent>
      </SectionHeader>
      <SubSectionHeader title='Sharing copy'>
        <SectionContent>
          <p>{`
              Use the '+ Share' link to create a new copy of the forecast.
            `}</p>
          <ul>
            <li>
              Enter the email address of the user to whom the copy is being
              shared.
            </li>
          </ul>
          <ImageContainer fileName='share-copy.png' alt='Cost and Expenses' />
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
