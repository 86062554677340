import React from "react";

type Props = { fileName: string; alt: string; style?: any };

export const ImageContainer = (props: Props) => {
  const { fileName, alt, style } = props;

  return (
    <React.Fragment>
      <div className='text-center imgContent'>
        <img
          // className='rounded'
          className='img-fluid'
          // className='rounded mx-auto d-block'
          src={require("../../images/" + fileName)}
          alt={alt}
          style={{
            height: "auto",
            justifyContent: "center",
            ...style,
          }}
        />
      </div>
    </React.Fragment>
  );
};
