import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";

export const OtherAssumptions = () => {
  return (
    <React.Fragment>
      <SectionHeader title='OTHER ASSUMPTIONS'>
        <SectionContent>
          <p>{`Seqnc provides features to add assumptions to your model that are not in the input spreadsheet. These include: (i) a section to add expansion revenues related to subscription/SaaS products; (ii) a comprehensive capital raising plan; (iii) assumptions related to starting balance sheet; and (iv) a comprehensive allocations section.`}</p>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
