import { Typography } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  children: any;
  id?: string;
};

export const SubSectionHeader = (props: Props) => {
  const { title } = props;
  return (
    <React.Fragment>
      <Typography className='subSection' id={props.id}>
        {title}
      </Typography>
      {props.children}
    </React.Fragment>
  );
};
