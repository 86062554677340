import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";
export const AdvancedOptions = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='CURRENT CUSTOMERS'>
        <SectionContent>
          <b>{`Forecast model: `}</b>
          {`The forecast model options are provided to build in a trial period (if applicable) so that you can then forecast a trial conversion %. Input spreadsheet will be generated with rows applicable for your selected option`}
          <br />
          <b>{`Trial period: `}</b>
          {` This section is enabled when Forecast model is selected as “Number of customers acquired - with trial period.”`}
          <br />
          <b>{`Upsell billings: `}</b>
          {`Specify upsell/expansion billings through one or more of the following options: (i) add upsell billing amounts directly; (ii) express as expansion through price increase at renewal; and (iii) express as increase in number of users over time. Input spreadsheet row/s will be added as applicable`}
          <br />
          <b>{`Renewal billing/prepayment term: `}</b>
          {`Specify whether you are providing invoiced amount or committed contract value (invoiced + uninvoiced amounts)? By selecting appropriate option.`}
          <br />
          {`Sales commission:`}
          <br />
          {`Seqnc automates comprehensive sales commissions calculations. You can use this section to describe how you commission products. Each product can have a different commission structure, and for advanced users, the model provides commissions expense deferral and recognition options. Note that you do not have to use this section if you prefer to add sales commissions as an operating expense directly; in such case leave the default value (=0) in this section.`}
          <table>
            <tr>
              <td>
                <>
                  <b>
                    {`1.       Commission on billing`}
                    <br />
                    {`1.1    Include upgrades in commissionable billings`}
                    <br />
                    {`1.2    Include one-time installation/setup fees in commissionable billings`}
                    <br />
                    {`2.       Sales commission as % of recognized revenues`}
                    <br />
                    {`3.       Sales commission as % of renewal billings`}
                    <br />
                    {`4.       Fixed (one time) sales commission paid per new customer acquired`}
                  </b>
                </>
              </td>
              <td>{`Select all the types of sales commissions that are applicable for this product. The model will automatically calculate the sales commissions for each product based on these inputs.`}</td>
            </tr>
          </table>
          <br />
          {`Renewals:`}
          <br />
          {`Seqnc allows you to flexibly define settings related to renewal options, including renewal term, billing frequency, and pricing - customized for however it fits your business model.`}
          <table>
            <tr>
              <td>
                <b>{`Renewal subscription term`}</b>
              </td>
              <td>
                <>
                  {`Options:`}
                  <br />
                  {`“Same renewal subscription term as initial subscription term”: Select this option if the subscription term for renewals is the same as for initial subscription`}
                  {`“Different renewal subscription term than initial subscription term”: Select this option if you offer a different renewal subscription term – for example, select this option if your initial subscription is for 12 months and customers can renew month to month after that term. System will prompt for additional input accordingly.`}
                </>
              </td>
            </tr>
            <tr>
              <td>
                <b>{`Renewal subscription term`}</b>
              </td>
              <td>
                <>{`Specify the renewal subscription term for this product/service. Default value is Month to month. This field Enabled if you select “Different renewal subscription term than initial subscription term” for “Renewal subscription term” field`}</>
              </td>
            </tr>
            <tr>
              <td>
                <b>{`Renewal billing/prepayment term`}</b>
              </td>
              <td>
                <>
                  {`Options:`}
                  <br />
                  {`“Same billing frequency/prepayment for renewal as for initial term”: Select this option if the billing frequency/prepayment for renewals is the same as for initial subscription`}
                  <br />
                  {`“Different billing frequency/prepayment for renewal than for initial term”: Select this option if billing frequency/prepayment term is different for renewals – for example, if you require prepayment for 12 months with the initial subscription and customers can pay month to month for renewals. System will prompt for additional input accordingly.`}
                </>
              </td>
            </tr>
          </table>
          <br />
          {`Different prepaid and subscription terms`}
          <table>
            <tr>
              <td>{`Your product has a prepayment term that is less than the subscription term. Select your billings assumption for the duration of the subscription term that was not prepaid.`}</td>
              <td>
                <>
                  {`1. After the initial prepayment, customers are billed for the same term as initial prepayment: Select this option if you bill customers for the same number of months prepaid as the initial prepayment term`}
                  <br />
                  {`2.After the initial prepayment, customers are billed month to month: Select this option if you bill customers month to month after the initial prepayment term`}
                </>
              </td>
            </tr>
            <tr>
              <td>{`Your product has a prepayment term that is less than the subscription term. Does churn at the end of each billing term or at the end of the subscription term ?`}</td>
              <td>
                <>
                  {`1. Churn occurs at end of subscription/contract period: Select this option if you expect churn occurs on at the end of the subscription term even if a customer has not prepaid for the full term. For example, if user has a 12 month subscription and pays monthly, you expect customers will not churn for 12 months.`}
                  <br />
                  {`2. Churn occurs at end of each billing period: Select this option if you expect churn to occur at every billing period when a customer has not prepaid for the full subscription term upfront. For example, if user has a 12 month subscription and pays monthly, you expect customers will churn every month`}
                </>
              </td>
            </tr>
          </table>
          <br />
          {`Installation/set up period`}
          <table>
            <tr>
              <td>
                <b>{`Does this product/service have an installation/setup/delivery period?`}</b>
              </td>
              <td>{`Select this option if the product/service involves an initial setup/installation. The model will defer recognition of revenues during the installation period.`}</td>
            </tr>
            <tr>
              <td>{`Check box if installation/setup period also applies for renewals (which is usually not the case)`}</td>
              <td>{`Select this this checkbox if installation/setup period also applies for renewals`}</td>
            </tr>
          </table>
          <br />
          {`Accounting policy - Recognition of installation setup fees`}
          <table>
            <tr>
              <td>
                <b>{`Method of revenue recognition for one-time setup/installation`}</b>
              </td>
              <td>
                <>
                  {`If you have one-time installation or setup fees for this product, you can select to recognize such revenues in one of the following options: `}
                  <br />
                  {`(i) Recognize over subscription/contract term;`}
                  <br />
                  {`(ii) Recognize over setup/installation period; `}
                  <br />
                  {`Recognize over expected customer lifetime; `}
                  <br />
                  {`Recognize in month of contract signing.`}
                </>
              </td>
            </tr>
          </table>
          <br />
          {`Revenue deferral option for product sale (non recurring)`}
          <table>
            <tr>
              <td>
                <b>{`Term over which revenue is recognized`}</b>
              </td>
              <td>{`If the term>1, a row will be added in the input spreadsheet for you to specify the amount of deferred revenues accrued from prior sales that you'd like to recognized in each forecast period`}</td>
            </tr>
          </table>
          <br />
          {`Report options`}
          <table>
            <tr>
              <td>
                <b>{`Do you want to include this product in the LTC/CAC report?`}</b>
              </td>
              <td>{`Select ‘Yes’ if you want to include the product in LTV/CAC report. If selected ‘NO’ then this product will not show up in LTV/CAC report`}</td>
            </tr>
          </table>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
