import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";

export const OtherIncome = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='OTHER INCOME (EXPENSE)'>
        <SectionContent>
          <p>
            Other income and expense items typically represent non-operating
            income or expense line items. Seqnc incorporates a dedicated line
            for non-operating income and expense, which is factored into the
            generation of the Profit and Loss (P&L) statement. Assumptions for
            other income and expense can be inputted in this sheet, allowing
            users to provide detailed and specific information regarding these
            non-operational financial elements.
          </p>
          <ImageContainer
            fileName='otherIncomeExpense.png'
            alt='Other Income Expense'
          />
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
