import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";
import { SubSectionHeader } from "../../../common/SubSectionHeader";

export const Workspace = () => {
  return (
    <React.Fragment>
      <SectionHeader title='Workspace'>
        <SectionContent>
          <p>
            The Workspace serves as a dynamic container crafted for the creation
            and modification of financial forecasts. Whether initiating a new
            forecast or updating an existing one, the Workspace enhances user
            efficiency.
          </p>
          <ImageContainer fileName='manage_forecast_link.png' alt='workspace' />
        </SectionContent>
      </SectionHeader>
      <SubSectionHeader title='Multi-Forecast Management'>
        <SectionContent>
          <p>
            Within the Workspace, users can open and manage multiple forecasts
            concurrently. This flexibility enables seamless switching between
            forecasts, facilitating efficient model updates.
          </p>
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title='Active Forecast Note' id='forecast_list'>
        <SectionContent>
          <p>
            While the Workspace supports multiple forecasts, only one can be in
            an active state at any given time. An active forecast is open for
            editing.
          </p>
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader
        title='Preserving Changes:'
        id='invite_user_to_collaborate'
      >
        <SectionContent>
          Changes made within the Workspace to a forecast, originally opened
          from a saved source, will not automatically overwrite the source
          forecast. The original remains unaltered until the user explicitly
          saves back the changes. This ensures that modifications within the
          Workspace are preserved separately from the original until the user
          decides to update the saved forecast.
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader
        title='Quick Links in Workspace Container::'
        id='invite_user_to_collaborate'
      >
        <SectionContent>
          The Workspace container provides quick access to the following key
          links:
          <ol>
            <li>
              Save Changes:
              <ul>
                <li>
                  Save modifications made within the Workspace to a forecast.
                </li>
              </ul>
            </li>
            <li>
              Add Collaborator or Share Copy:
              <ul>
                <li>Manage collaborators for in-progress forecasts.</li>
                <li>Create and share copies of forecasts.</li>
              </ul>
            </li>
            <li>
              Open a Saved Forecast:
              <ul>
                <li>Easily access and open a previously saved forecast.</li>
              </ul>
            </li>
            <li>
              Create a New Forecast:
              <ul>
                <li>
                  Initiate a new forecast with options to start with an empty
                  model, from a shared copy, or directly from real-time data
                  sources like QuickBooks/XERO/Zoho Books.
                </li>
              </ul>
            </li>
          </ol>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
