import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  id: string;
  displayName: string;
  path: string;
  setCurrentLink?: Function;
  currentLink: string;
  children: any;
};
export const Subsection = (props: Props) => {
  const { id, path, displayName, currentLink, setCurrentLink, children } =
    props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // let isSectionActive = document.querySelectorAll(
      //   `#${id}_subsection .menu-item-active`
      // ).length;
      // let childLinks = document.querySelectorAll(
      //   `#${id}_subsection .sq-sub-nav-link`
      // );
      // if (!isSectionActive) (childLinks[0] as HTMLElement).click();
    }
  }, [isOpen]);

  function toggle() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if (isOpen && path) {
      navigate(path);
    }
  }, [isOpen, path]);

  return (
    <div>
      <a
        className={`sq-nav-item1 ${
          isOpen ? "menuItemExpanded menu-item-active" : ""
        }`}
        id={id}
        onClick={() => {
          toggle();
        }}
      >
        <div className='title' style={{ display: "inline-block" }}>
          {displayName}
        </div>
        {/* <span className='sq-snl-tick' /> <span className='sq-snl-dot' /> */}
        <span className='sq-n-arrow-down1' />{" "}
      </a>
      <div
        className={`sq-sub-nav1 ${isOpen ? "d-block" : "d-none"}`}
        id={`${id}_subsection`}
      >
        {props.children}
      </div>
    </div>
  );
};
