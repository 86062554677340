import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Parts } from "./Path";

type Props = {
  displayName: string;
  openByDefault?: boolean;
  liId: string;
  spanId: string;
  children?: any;
  path: string;
  currentLink: string;
  setCurrentLink: Function;
};

export const Section = (props: Props) => {
  const {
    displayName,
    openByDefault,
    liId,
    spanId,
    path,
    currentLink,
    setCurrentLink,
  } = props;
  const [isOpen, setIsOpen] = useState(openByDefault ? true : false);
  useEffect(() => {
    if (openByDefault) {
      setIsOpen(true);
    }
  }, [openByDefault]);

  const navigate = useNavigate();

  function toggle() {
    setIsOpen(!isOpen);
  }

  const location = useLocation();
  useEffect(() => {
    if (
      path &&
      location.pathname &&
      (path === location.pathname || location.pathname.includes(path))
    ) {
      setIsOpen(true);
    } else setIsOpen(false);
  }, [location]);

  useEffect(() => {
    if (isOpen && path) {
      navigate(path);
    }
  }, [isOpen, path]);

  return (
    <React.Fragment>
      <li id={liId}>
        <a
          className='sq-nav-item sq-nav-item-1 sq-nav-item-active'
          data-tour='createModelExpand'
          onClick={toggle}
        >
          <span className='sq-n-title' id={spanId}>
            {displayName}
          </span>{" "}
          <span className={`sq-n-arrow ${isOpen ? "d-none" : "d-block"}`} />{" "}
          <span
            className={`sq-n-arrow-down ${isOpen ? "d-block " : "d-none"}`}
          />
          <div className='clear' />
        </a>
        <div
          className={`sq-sub-nav sq-sub-nav-1 ${
            isOpen ? " d-block" : " d-none"
          }`}
        >
          {props.children}
        </div>
      </li>
    </React.Fragment>
  );
};
