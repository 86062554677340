export const URLPart = Object.freeze({
  CONTENT: "content",
  HELP: "",
  INTRODUCTION: "/",
  SIGNUP: "signup",
  ONBOARD: "onboard",
  MODULES: "modules",
  FORECASTS: "forecasts",
  COLLABORATION_COPIES: "collaborationCopies",
  WORKSPACE: "workspace",
  CONNECT_TO_OTHER_APPLICATIONS: "connectToOtherApplications",
  MANAGE_ACCOUNT: "manageAccounts",
  MANAGE_TEAM: "manageTeam",
  CREATE_MODEL: "createModel",
  PRODUCT_SERVICES: "productAndServices",
  JOB_ROLES: "jobRoles",
  EXPENSES: "expenses",
  INVENTORIES: "inventoryPurchases",
  FIXED_ASSETS: "fixedAssets",
  FORMAT_VIEW: "formatViewSettings",
  INPUT_ASSUMPTIONS: "inputAssumptions",
  GENERAL_ASSUMPTIONS: "generalAssumptions",
  INPUT_SPREADSHEET: "inputSpreadSheet",
  OTHER_ASSUMPTIONS: "otherAssumptions",
  STARTING_BS_SHEET: "startingBalanceSheet",
  CAPITAL_PLAN: "capitalPlan",
  ALLOCATIONS: "allocations",
  REPORTS: "reports",
  FINANCIAL_PROJECTIONS: "financialProjections",
  BILLING_AND_REVENUES: "billingAndRevenues",
  CAPITAL_REQUIRED: "capitalRequired",
  SUBSCRIPTION_METRICS: "subscriptionMetrics",
  SUMMARY: "summary",
  SCENARIO_COMPARISION: "scenarioComparison",
  TRACK_PERFORMANCE: "trackPerformance",
  CONFIGURE_DATASOURCE: "configureDatasource",
  FORECAST_TO_ACTUAL: "forecastToActual",
  ACTUAL_BREAKUP:"actualBreakup",
  ROLLING_FORECAST: "rollingForecast",
  WATCH_WINDOW: "watchWindow",
  // SETTINGS: "settings",
  // COMPANY_PROFILE: "companyProfile",
  // MANAGE_FORECAST_SCENARIOS: "manageForecastScenarios",
  // SAVE_SHARE_YOUR_MODEL: "saveShareYourModel",
  // CREATE_SHARE_TEMPLATE: "createShareTemplate",
  // IMPORT_MODEL: "importModel",
  // MANAGE_ACCOUNT_TEAM: "manageAccountAndTeam",
  // SWITCH_ACCOUNT: "switchAccount",
  // RESET_MODEL: "resetModel",
});
