import React from "react";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";
import { ImageContainer } from "../../common/ImageContainer";

export const CapitalRequired = () => {
  return (
    <React.Fragment>
      <SectionHeader title='CAPITAL REQUIRED' id='capitalRequired'>
        <SectionContent>
          <p>
            The "Capital Required" reports provide a detailed analysis of the
            capital requirements based on your scenario assumptions, offering
            both a comprehensive and summarized view of forecasted sources and
            uses of capital.
          </p>
          <ImageContainer
            fileName='capitalRequired.png'
            alt='Capital Required'
          />
          <b>Capital Required Metrics:</b>
          <p>
            The Capital Required report includes key metrics such as:
            <ul>
              <li>
                Monthly capital required before any capital addition (equity or
                debt), representing the operating shortfall.
              </li>
              <li>Cumulative capital (operating shortfall) required.</li>
              <li>
                Adjusted capital required after accounting for any debt or
                equity added during the forecast period, indicating the
                remaining shortfall that the company needs to address.
              </li>
            </ul>
          </p>
          <b>Sources & Uses (Detail):</b>
          <p>
            This detailed report offers a breakdown of all capital sources and
            uses according to your forecast assumptions. It provides valuable
            insights that can be used to adjust assumptions and align with
            specific business objectives, including achieving a target capital
            requirement.
          </p>
          <b>Sources & Uses (Summary):</b>
          <p>
            A concise summary of sources and uses, showcasing forecasted changes
            in selected income statement and balance sheet accounts. This
            summary provides a quick overview of the capital dynamics within the
            forecast period.
          </p>
          <b>Sources & Uses Report:</b>
          <p>
            This report facilitates a detailed comparison between the uses and
            sources of capital. It enables a comprehensive understanding of how
            capital is allocated and utilized within your business, supporting
            strategic decision-making processes.
          </p>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
