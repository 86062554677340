import { AppBar, Toolbar, Typography } from "@mui/material";
import React from "react";

type Props = {
  setToggleNavPanel: Function;
};

export const Bar = (props: Props) => {
  const { setToggleNavPanel } = props;

  return (
    <React.Fragment>
      <AppBar>
        <Toolbar style={{ minHeight: "30px" }}>
          <div className='sq-mp-header-left'>
            <a className='sq-header-logo' href='/' data-alt='seqnc'>
              <img className='seqnc-logo-header' alt='Seqnc logo' />
            </a>
            <a
              className='sq-nav-toggle-btn'
              onClick={() => {
                setToggleNavPanel();
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
