import React from "react";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";
import { ImageContainer } from "../../common/ImageContainer";

export const SubscriptionMetrics = () => {
  return (
    <React.Fragment>
      <SectionHeader title='SUBSCRIPTION METRICS' id='subscriptionMetrics'>
        <SectionContent>
          <p>
            The Subscription Metrics reports offer a detailed forecast of
            subscription and SaaS metrics based on your specified assumptions.
            These insights are crucial for companies with subscription-based
            business models, providing a comprehensive understanding of growth
            drivers and key metrics.
          </p>
          <ImageContainer
            fileName='subscriptionMetrics.png'
            alt='Subscription Metrics'
          />
          <b>MRR (All Products):</b>
          <p>
            This report provides a summary of the total Monthly Recurring
            Revenues (MRR) forecast for all products on a monthly basis
            throughout the forecast period. It offers a consolidated view of the
            recurring revenue landscape.
          </p>
          <b>MRR (By Product):</b>
          <p>
            Gain insights into the Monthly Recurring Revenues (MRR) forecast for
            each product on a monthly basis throughout the forecast period. This
            report provides a product-specific breakdown of recurring revenues,
            facilitating a granular analysis of revenue streams.
          </p>
          <b>Subscription Billings:</b>
          <p>
            Subscription billings represent the sum of new revenues and the
            change in deferred revenues within a given period. This metric
            indicates the addition of deferred revenues relative to recognized
            revenues, offering insights into the strength of revenue growth not
            fully captured in recognized revenue numbers.
          </p>
          <b>Customer Net Adds:</b>
          <p>
            This report provides a detailed view of the forecasted number of
            customers, including those at the beginning, added, upgraded,
            downgraded, and lost during the forecast period. Additionally, it
            estimates negative churn percentage monthly based on your specified
            assumptions.
          </p>
          <b>LTV and CAC (By Product):</b>
          <p>
            This report includes the following key metrics:
            <ul>
              <li>
                Expected customer lifetime (months): An estimate of the number
                of months a subscription customer is likely to remain a customer
                based on your churn assumptions.
              </li>
              <li>
                LTV (average per customer): An estimate of the lifetime value of
                revenues attributed to a customer on average, particularly
                relevant for products with recurring revenue business models.
              </li>
              <li>
                CAC (average per customer): Customer Acquisition Costs, an
                estimate of the average cost to acquire a customer.
              </li>
              <li>
                LTV/CAC ratio: A crucial metric measuring the value of a
                customer relative to the cost of acquiring that customer. This
                ratio provides insights into the efficiency and sustainability
                of customer acquisition strategies.
              </li>
            </ul>
          </p>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
