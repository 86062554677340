import React from "react";
import { SubSectionHeader } from "../../../../common/SubSectionHeader";
import { SectionContent } from "../../../../common/SectionContent";
import { ImageContainer } from "../../../../common/ImageContainer";

export const NewDebt = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='DEBT' id='newdebt'>
        <SectionContent>
          <p>
            Managing debt is a crucial component of financial modeling, and
            Seqnc provides a comprehensive framework to handle various types of
            debt. Below is a guide on how to effectively manage Term Debt,
            Convertible Debt, and Venture Debt:
          </p>
          <ImageContainer fileName='newDebt.png' alt='New Debt' />
          <b>Adding New Debt:</b>
          <ol type='1'>
            <li>
              Navigate to the 'Debt' section under 'Capital plan' in the
              application.
            </li>
            <li>Click on the 'Add Debt' button to initiate the process.</li>
            <li>
              Select the type of debt you want to add: Term Debt, Convertible
              Debt, or Venture Debt.
            </li>
            <li>
              Fill in the required details specific to the chosen debt type,
              including principal amount, interest rate, term, and other
              pertinent information.
            </li>
            <li>
              Specify the start date of the debt, marking the commencement of
              the obligation.
            </li>
          </ol>
          <b>Managing Debt Terms:</b>
          <ol type='1'>
            <li>
              <i> Term debt:</i>
              <ul>
                <li>
                  Seqnc generates an amortization schedule for Term Debt,
                  illustrating how principal and interest payments are
                  distributed over time.
                </li>
              </ul>
            </li>
            <li>
              <i> Convertible Note:</i>
              <ul>
                <li>
                  For Convertible Debt, specify the conversion terms, such as
                  conversion price and conversion trigger events.
                </li>
              </ul>
            </li>
            <li>
              <i> Venture Debt:</i>
              <ul>
                <li>
                  In the case of Venture Debt, define any covenants or unique
                  terms associated with the funding structure.
                </li>
              </ul>
            </li>
          </ol>
          <b>Interest Calculation Methods:</b>
          <ol type='1'>
            <li>
              <i>Equal Payment:</i>
              <ul>
                <li>
                  Under this method, each payment is of equal amount, covering
                  both principal and interest. Seqnc generates an amortization
                  schedule reflecting the distribution of payments.
                </li>
              </ul>
            </li>
            <li>
              <i>Equal Principal:</i>
              <ul>
                <li>
                  With this method, the principal repayment remains constant,
                  resulting in decreasing interest payments over time. Seqnc
                  provides a detailed schedule illustrating the principal
                  reduction.
                </li>
              </ul>
            </li>
            <li>
              <i>Interest Payments Only - Principal Paid at Maturity:</i>
              <ul>
                <li>
                  This method allows you to pay only the interest during the
                  term, with the principal amount paid in full at the maturity
                  date. Seqnc calculates the interest payments accordingly.
                </li>
              </ul>
            </li>
            <li>
              <i> Interest & Principal Accrued Until Maturity:</i>
              <ul>
                <li>
                  This method combines interest and principal accrual throughout
                  the term, with both components paid in full at maturity. Seqnc
                  generates a comprehensive schedule outlining the accrual and
                  payment details.
                </li>
              </ul>
            </li>
          </ol>
          <p>{`For each debt line item you add, specify the following details:`}</p>
          <table>
            {" "}
            <tr>
              <td>
                <b>Debt description</b>
              </td>
              <td>{`Line item description will be displayed in financial statement`}</td>
            </tr>
            <tr>
              <td>
                <b>Type of debt</b>
              </td>
              <td>{`Term debt, Convertible note, venture debt`}</td>
            </tr>
            <tr>
              <td>
                <b>If convertible note, assume repay or convert at maturity?</b>
              </td>
              <td>{`If this is a convertible note, do you want to assume this debt would be repaid or converted to equity?`}</td>
            </tr>
            <tr>
              <td>
                <b>Amount of debt</b>
              </td>
              <td>{`Specify Amount of debt`}</td>
            </tr>
            <tr>
              <td>
                <b>Month in which debt is planned to be raised</b>
              </td>
              <td>{`The month in which you expect to raise this debt`}</td>
            </tr>
            <tr>
              <td>
                <b>Term of debt</b>
              </td>
              <td>{`Term of debt (in months)`}</td>
            </tr>
            <tr>
              <td>
                <b>Interest rate on debt</b>
              </td>
              <td>{`Annual interest rate on the debt`}</td>
            </tr>
            <tr>
              <td>
                <b>Interest calculation method</b>
              </td>
              <td>{`Select from Equal payment, Equal principal, Interest payments only -Principal paid at maturity, or Interest & Principal accrued until maturity.`}</td>
            </tr>
            <tr>
              <td>
                <b>Interest only period (max: term - 1)</b>
              </td>
              <td>{`Interest only period: The number of months in which you will only pay interest (if applicable)`}</td>
            </tr>
            <tr>
              <td>
                <b>Facility fee</b>
              </td>
              <td>{`Facility fees as % of debt, if applicable`}</td>
            </tr>
            <tr>
              <td>
                <b>Exit fee</b>
              </td>
              <td>{`Exit fees as % of debt, if applicable`}</td>
            </tr>
          </table>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
