import React from "react";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { ImageContainer } from "../../../common/ImageContainer";
import GoToLink from "../../../common/GoToLink";
import GoToLink1 from "../../../common/GoToLink1";

export const FixedAssets = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title="FIXED ASSET PURCHASES">
        <SectionContent>
          <p>
            In the 'Fixed Assets' spreadsheet, you can input assumptions for
            fixed asset purchases, commonly referred to as capital expenditures.
            This involves specifying assumptions for each period and type of
            fixed asset. The model automatically generates a depreciation
            schedule based on the method selected for each asset during the
            initial setup in the 'Model Setup' section.
          </p>
          <GoToLink1
            goToLink="/app/fixed-assets"
            goToText="Fixed asset purchases"
            parentSection="Input assumptions/ Input spreadsheet"
          />

          <p>
            This streamlined process allows users to project and manage capital
            expenditures over different periods while accounting for the
            depreciation of each asset, providing a comprehensive overview of
            the financial impact of fixed asset purchases in the model.
          </p>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
