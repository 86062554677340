import React from "react";
import { SectionHeader } from "../../common/SectionHeader";
import { SectionContent } from "../../common/SectionContent";
import { ImageContainer } from "../../common/ImageContainer";

export const RollingForecast = () => {
  return (
    <React.Fragment>
      <SectionHeader title='ROLLING FORECAST' id='rollingForecast'>
        <SectionContent>
          <p>
            Rolling forecast reports provide a dynamic and forward-looking
            perspective on financial performance, allowing users to anticipate
            future trends and make informed decisions. This user guide outlines
            key steps to effectively generate and interpret rolling forecast
            reports in the financial domain.
          </p>
          <ImageContainer
            fileName='rollingForecast.png'
            alt='Rolling Forecast'
          />
          <ol>
            <li>
              Accessing Rolling Forecast Reports: Click on 'Rolling forecast'
              section under 'Track performance' menu under the left navigation
              menu
            </li>
            <li>Supported reports : P&L report</li>
            <li>
              Supported charts : Revenues, Cost of Sales, Gross profit (loss),
              Operating expenses, Operating Income
            </li>
            <li>
              Downloading Excel files is a straightforward process that allows
              you to save data for offline access or further analysis.
            </li>
            <li>
              Settings
              <ul>
                <li>
                  Locate the option to switch between 'Table' and 'Chart' views.
                </li>
                <li>
                  Choose the desired range for your data, whether it's a
                  specific date range.
                </li>
                <li>Toggling Display Labels in the chart view</li>
              </ul>
              <ImageContainer fileName='rfSettings.png' alt='RF settings' />
            </li>
          </ol>
          <p>
            Rolling forecast reports empower users with real-time insights,
            facilitating proactive decision-making in the financial domain.
            Regularly leverage these reports to stay ahead of financial trends
            and optimize your organization's performance.
          </p>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
