import React from "react";
import { ImageContainer } from "../../../common/ImageContainer";
import { SectionContent } from "../../../common/SectionContent";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import BulkAddNote from "../../../common/BulkAddNote";
import GoToLink from "../../../common/GoToLink";

export const JobRoles = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title="JOBS/ROLES">
        <SectionContent>
          <p>
            The "Job/Roles" section within Seqnc allows you to comprehensively
            manage roles, calculate salaries and benefits costs, and generate a
            headcount summary in reports. Follow these steps to effectively
            navigate and utilize this feature.
          </p>
          <p>{`Define each job/role that you would like to include in a headcount/workforce forecast. You can define jobs/roles at any level of granularity depending on how you would like to forecast your workforce plan. For example, you can add a single job/role “Engineer” or you can add multiple specialties of engineering as separate jobs/roles and forecast accordingly.`}</p>
          <p>{`A job/role is classified either as a Direct Cost or Operating Expense. A Direct Cost is variable and directly linked to the cost of producing a product or service. An operating expense is a general expense that supports the organization’s operations. All roles are organized by type and displayed in their respective tabs.`}</p>
          <BulkAddNote lineItemName="Jobs/Roles" />
          <GoToLink
            goToLink="/app/roles"
            lineItemName="role"
            goToText="Jobs/Roles & Departments"
          />
          <ImageContainer fileName="DirectCostRole.png" alt="directJob/Role" />
          <p>
          For Direct Costs, a new question item has been added to allow the selection of a product that is specific to the corresponding job/role or select "Not Product 
          Specific" if none applies.. This does not apply to Operating Expenses.
          </p>
          <ImageContainer fileName="jobroles.png" alt="Job/Role" />
          <p>
            Adding Job/Roles:
            <ol>
              <li>
                Navigate to the "Job/Roles & Department" section under "Create
                Model" in the left navigation menu
              </li>
              <li>
                Utilize the 'Add Job/Role' button to seamlessly include new
                roles.
              </li>
            </ol>
          </p>
          <p>
            Editing/Deleting Roles: In the summary table, use the respective role
            edit icon to update or delete a role.
          </p>
          <p>
            Inputting Assumptions:
            <ol>
              <li>
                Navigate to the "Input Spreadsheet" under "Input Assumptions" in
                the left navigation menu.
              </li>
              <li>
                Utilize the "Workforce Plan (Direct)" and "Workforce Plan
                (Opex)" sheets to input assumptions for salaries, benefits, and
                workforce planning.
              </li>
            </ol>
          </p>
          <p>
            Completing this section enables Seqnc to calculate salaries/benefits
            costs and generate a headcount summary in reports. Alternatively,
            you can add a row for salaries/benefits under Direct Costs or
            Operating Expenses to input assumptions directly.
          </p>
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
