import React from "react";
import { SectionContent } from "../../common/SectionContent";
import { SectionHeader } from "../../common/SectionHeader";
import { ImageContainer } from "../../common/ImageContainer";
import { Col, Row } from "react-bootstrap";

export const ScenarioComparision = () => {
  return (
    <React.Fragment>
      <SectionHeader title='SCENARIO COMPARISON' id='scenarioComparision'>
        <SectionContent>
          <p>
            With the Scenario Comparison feature, you have the capability to
            compare values from multiple scenarios, presenting them in either
            chart view or table view, and providing the flexibility to export
            both the chart and table. Refer to the Scenario Comparison Settings
            section for details on customization options to alter the rendering.
          </p>
          <Row>
            <Col xs={16} md={8}>
              <ImageContainer
                fileName='scenarioComparision.png'
                alt='Scenario Comparision'
              />
            </Col>
            <Col xs={8} md={4}>
              <ImageContainer
                fileName='scenarioComparisionTable.png'
                alt='Scenario Comparision Table'
                style={{ height: "500px" }}
              />
            </Col>
          </Row>

          <b>Charts Included:</b>
          <ol>
            <li>Billings</li>
            <li>Revenues</li>
            <li>Costs & Expenses</li>
            <li>Operating Surplus (Shortfall)</li>
            <li>Sales (Number of Customers)</li>
            <li>MRR (Monthly Recurring Revenue)</li>
          </ol>
          <b>Comparison Table Details:</b>
          <ol>
            <li>P&L Summary (Profit and Loss)</li>
            <li>Growth Metrics</li>
            <li>Capital Requirements</li>
            <li>Operating Metrics</li>
            <li>Number of Customers*</li>
            <li>LTV/CAC (Lifetime Value to Customer Acquisition Cost) Ratio</li>
          </ol>
        </SectionContent>
      </SectionHeader>
      <SectionHeader title='SCENARIO COMPARISON SETTINGS'>
        <SectionContent>
          <b>Scenario Comparison Settings:</b>
          <ol>
            <li>
              Selection of Scenarios:
              <ul>
                <li>
                  To compare scenarios, select two or more scenarios by entering
                  Shift+left_click for each.
                </li>
              </ul>
              <ul>
                <li>
                  To remove scenarios selected for comparison, click on the
                  Reset button.
                </li>
              </ul>
            </li>
            <li>
              Chart Generation:
              <ul>
                <li>
                  Seqnc will automatically generate charts to compare the
                  scenarios you've selected.
                </li>
              </ul>
            </li>
            <li>
              Time Period Selection:
              <ul>
                <li>
                  Choose specific time periods for comparison to focus on
                  relevant data points.
                </li>
              </ul>
            </li>
          </ol>
          <ImageContainer
            fileName='scenarioComparisionSetings.png'
            alt='Scenario Comparision'
            style={{ height: "450px" }}
          />
          <p>
            This robust Scenario Comparison tool empowers you to visualize and
            analyze different scenarios comprehensively, facilitating better
            decision-making and strategic planning for your business.
          </p>
        </SectionContent>
      </SectionHeader>
    </React.Fragment>
  );
};
