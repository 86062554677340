import React from "react";
import { SectionContent } from "../../../common/SectionContent";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { ImageContainer } from "../../../common/ImageContainer";

export const Categories = () => {
  return (
    <React.Fragment>
      <SubSectionHeader title='PRODUCT CATEGORY'>
        <SectionContent>
          {`To categorize similar products and services, simply right click “Products and Services” at the top of the dop down menu and select “Add Category.”`}
          <br />
          <ImageContainer fileName='productCateogry.png' alt='Categories' />
          <br />
          {`To place a product/service in a category, click and drag the product/service and place it into the category. When creating a new product/service, there is an option to categorize, and all categories will be available to select.`}
        </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
